.rumah{
    background-color: #f5ffeb;
    margin-top: -60px;
    padding-bottom: 50px;
    @media (max-width: 768px){
        margin-top: 135px;
    }
    .container-header1{
        justify-content: center;
        margin-top: 4%;
        padding-bottom: 10%;
        font-size: 50px;
        font-family: 'Poppins';
        font-weight: 600;
        text-align: center;
        color: #172e69;
        @media (max-width: 768px){
            margin-top: -15%;
            font-size: 36px;
        }
    }
    .Judul{
        justify-content: center;
        margin-top: 1%;
        margin-bottom: 10px;
        font-size: 40px;
        font-family: 'Poppins' sans-serif;
        font-weight: 600;
        text-align: center;
        color: #172e69;
        @media (max-width: 768px){
            //margin-top: -15%;
            font-size: 24px;
        }
    }
    .rumah-card{
        @media (max-width: 768px){
            margin-top: -15%;}
    }
}
