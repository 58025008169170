.background-container {
    background-image: url("../../Media/Greenwood//image.webp"); /* Replace with your desktop image URL */
    background-size: cover;
    background-position: center;
    justify-content: center;
    align-items: center;
    height: 100vh;
    
    @media (max-width: 768px) {
      background-image: url("../../../src/Media/Greenwood//header_mobile.webp");
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center; 
    
    }
  }